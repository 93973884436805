<template>
  <v-container fluid grey lighten-3 pa-10>
    <v-row class="justify-center">
      <v-col>
        <v-card class="mx-auto" max-width="450">
          <v-card-title>แก้ไขข้อมูล สาขา</v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="form" @submit.prevent="submit">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.name"
                      :rules="nameRules"
                      :counter="50"
                      label="ชื่อ สาขา"
                      id="name"
                      maxlength="50"
                    ></v-text-field>
                  </v-col>

                   <v-col cols="12">
                        <v-textarea
                        v-model="item.detail"
                        label="รายละเอียด"
                        id="detail"
                        ></v-textarea>
                    </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="item.tag"
                      :counter="3"
                      :rules="[
                        () =>
                          (!!item.tag && item.tag.length == 3) ||
                          'กรุณากรอกให้ถูกต้อง'
                      ]"
                      maxlength="3"
                      label="tag"
                      id="tag"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="item.no"
                      type="number"
                      label="No ลำดับ"
                      id="no"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-file-input
                      label="File input"
                      accept="image/*"
                      prepend-icon="mdi-camera"
                      type="file"
                      @change="onFileSelected"
                    ></v-file-input>

                    <div v-if="isOldImage">
                      <v-img
                        :src="oldImageUrl"
                        max-height="150"
                        max-width="150"
                        class="mt-2"
                      ></v-img>
                      <span>{{ oldImageUrl }}</span>
                    </div>
                    <div v-else>
                      <v-img
                        v-if="imageUrl"
                        :src="imageUrl"
                        max-height="150"
                        max-width="150"
                        class="mt-2"
                      ></v-img>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-switch
                      v-model="item.active"
                      label="active"
                      color="success"
                      input-value="1"
                      ref="showImg"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-divider class="mt-5 mb-5"></v-divider>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn color="gray" class="mr-5" @click="cancel"
                    >ยกเลิก</v-btn
                  >
                  <v-btn :disabled="!valid" color="success" type="submit"
                    >บันทึก</v-btn
                  >
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/api";

export default {
  name: "BranchEdit",
  data() {
    return {
      item: {
        name: "",
        tag: "",
        image: "",
        no: 0,
        active: 0
      },
      nameRules: [
        v => !!v || "Name is required",
        v =>
          (typeof v != "undefined" && v.length <= 50) ||
          "Name must be less than 50 characters"
      ],
      imageUrl: null,
      valid: true,
      isOldImage: false,
      oldImageUrl: "",
      imageFile: null
    };
  },
  methods: {
    async submit() {
      //alert(JSON.stringify(this.item))
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("id", this.item.id);
        formData.append("name", this.item.name);
        formData.append("detail", this.item.detail);
        formData.append("tag", this.item.tag);
        formData.append("no", this.item.no);
        formData.append("active", this.item.active ? 1 : 0);
        if (this.imageFile != null) {
            formData.append("image", this.imageFile);
        }

        await api.updateBranch(formData);
        this.$router.push("/branch");
      }
    },
    onFileSelected(event) {
      const reader = new FileReader();

      reader.onload = event => {
        this.isOldImage = false;
        this.imageUrl = event.target.result; //สำหรับ preview ข้อมูลรูปภาพ data:image/jpeg;base64fdasfdasfdasf .....
      };

      if (event) {
        reader.readAsDataURL(event);
        this.imageFile = event; // สำหรับ upload
      } else {
        //กดปุ่ม x ยกเลิก ที่อัพโหลดภาพ
        this.isOldImage = true;
        this.imageFile = null;
      }
    },
    cancel() {
      this.$router.push("/Branch");
    },
    getImage() {
      if (this.item.image) {
        if (this.imageUrl) {
          return this.imageUrl;
        } else {
          // การเข้าถึง filter แบบ global ที่เราสร้างขึ้นมาในไฟล์ main.js ถ้าเรียกใช้งานตรง template จะเป็น   image | imageUrl *การเรียกใช้งาน filter
          return this.$options.filters.imageUrl(this.oldImage);
        }
      }
    }
  },
  async mounted() {
    let result = await api.getBranchById(this.$route.params.id);
    if (result.data.image) {
      this.isOldImage = true;
      this.oldImageUrl = this.$options.filters.imageUrl(result.data.image);
    }

    this.item = result.data;
  },
  computed: {}
};
</script>

<style></style>
